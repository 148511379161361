<template>
  <InvitationPage
    :class="` ${$vuetify.breakpoint.xsOnly ? '' : 'mt-2'}`"
    :tour-guide="tourGuide"
    :preview="true"
    :meals="meals"
    :transports="transports"
    :names="$store.state.eventData.names"
    :location-name="$store.state.eventData.location.eventLocationName"
    :location-geo="$store.state.eventData.location"
    :date="$store.state.eventData.date"
    :img="getFullImageUrl($store.state.eventData.image)"
    :lang="$i18n.locale"
    :default-lang="$store.state.eventData.defaultLang"
    :guest-response="{}"
  ></InvitationPage>
</template>

<script>
import InvitationPage from '@/components/EventDetails/InvitationPage.vue'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { getFullImageUrl } from '@core/utils/ImageFromApi'
import { mdiClose } from '@mdi/js'

export default {
  components: { InvitationPage },
  props: {
    tourGuide: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      icons: {
        mdiClose,
      },
      getFullImageUrl,
    }
  },
  computed: {
    meals() {
      return store.state.eventData?.specialMeals?.filter(el => el.value !== 0).map(el => i18n.t(el.name)) || []
    },
    transports() {
      return store.state.eventData?.transports?.map(el => el.name) || []
    },
  },
}
</script>
